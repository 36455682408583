import React from 'react';
import './About.css';

const About = () => {
    return (
        <div className='about-page' id='about'>
            <div className='about-content'>
                <div className='about-title'>
                    {"<About />"}
                </div>
                <div className='about-content-text'>
                    I'm a tech enthusiast with a strong background in computer science and a genuine passion for exploring innovative solutions.
                    My journey in tech has equipped me with the skills to thrive in collaborative and ever-evolving environments.
                    I'm often recognized for my adaptability, team spirit, and my knack for staying calm when the going gets tough.
                    Outside of work, I'm known for injecting a bit of humor and positivity into the team, making sure we enjoy our time together.
                    I firmly believe in the power of continuous learning and enjoy embracing new challenges.
                    I'm excited about the opportunity to contribute my technical know-how and problem-solving abilities to an innovative organization, where I can be part of something exciting and make a real impact.
                </div>
            </div>
        </div>
    );
}

export default About;